<template>
    <div data-cy="top-nav" :class="`top-0 left-0 w-full ${navHasBg ? 'fixed' : 'absolute'}`" style="z-index: 101">
        <nav class="navbar">
            <div
                class="bg-background border-b border-light-grey absolute top-0 left-0 w-full h-full trans-opacity"
                :class="{ 'opacity-0': !navHasBg }"
            ></div>
            <div class="h-full flex flex-col px-2 xs:px-4">
                <div class="my-1 lg:my-2 h-full lg:my-0 overflow-visible">
                    <div class="h-full items-center flex w-full">
                        <!-- Logo Link -->
                        <div class="flex flex-grow relative">
                            <NavLogo :showWhite="showWhiteVersion" data-cy="logo" />
                        </div>
                        <div class="flex items-center text-portal-sm">
                            <!-- Menu options -->
                            <ul>
                                <li v-for="link in menuLinks" :key="`navlink-${link.to}`" :data-cy="`navlink-${link.to}`">
                                    <router-link v-if="$auth.loggedIn()" :to="`/${link.to}`" class="relative block rounded-full nav-btn-padding">
                                        <span
                                            class="text-black relative z-10 text-portal-sm block"
                                            :class="[
                                                { 'text-white': whiteNavToStart },
                                                { 'text-primary sm:text-white':
                                                    (showWhiteVersion) ||
                                                    (!whiteNavToStart && currentRouteName === link.to)
                                                },
                                            ]"
                                        >
                                            {{ link.name }}
                                        </span>
                                        <span
                                            class="btn-bg bg-white sm:bg-primary"
                                            :class="{ 'opacity-0': !showWhiteVersion || currentRouteName !== link.to }"
                                        ></span>
                                        <span
                                            class="btn-bg bg-black"
                                            :class="{ 'opacity-0': showWhiteVersion || currentRouteName !== link.to }"
                                        ></span>
                                    </router-link>
                                </li>
                            </ul>
                            <!-- Logged out - join email list OR login/register, depending on config -->
                            <div v-if="!loggedIn && portalConfig.login_allowed" class="flex items-center text-sm xs:text-portal-sm">
                                <router-link
                                    data-cy="topnav-register"
                                    to="/register"
                                    class="relative block rounded-full nav-btn-padding text-white mr-1 xs:mr-2"
                                    :class="{ 'text-primary sm:text-white': showWhiteVersion }"
                                >
                                    <span class="btn-bg bg-white sm:bg-primary" :class="{ 'opacity-0': !showWhiteVersion }"></span>
                                    <span class="btn-bg bg-black" :class="{ 'opacity-0': showWhiteVersion }"></span>
                                    <span class="relative">{{ strings.signup }}</span>
                                </router-link>
                                <router-link
                                    data-cy="topnav-login"
                                    to="/login"
                                    class="relative block rounded-full nav-btn-padding"
                                    :class="{ 'text-white sm:text-primary': showWhiteVersion }"
                                >
                                    <span class="btn-bg border border-white sm:border-primary" :class="{ 'opacity-0': !showWhiteVersion }"></span>
                                    <span class="btn-bg border" :class="{ 'opacity-0': showWhiteVersion }"></span>
                                    <span class="relative">{{ strings.login }}</span>
                                </router-link>
                            </div>
                            <JoinEmailList
                                v-else-if="!loggedIn"
                                :showWhite="showWhiteVersion"
                                id="topnav-desktop"
                                class="relative"
                                style="top: 2px"
                            />
                            <!-- Account options include log in / log out depending on status -->
                            <AccountOptions :showWhiteVersion="showWhiteVersion" :currentRouteName="currentRouteName" :loggedIn="loggedIn" />
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
// import { setLSItem } from "@/js/helpers/localstorage";
// import SvgSymbols from "@/components/ui/layouts/SvgSymbols";
import JoinEmailList from "./joinEmailList";
import AccountOptions from "./accountOptions";
import NavLogo from "./navLogo";
// import { User } from "@/data/models";
import text from "./topNavText";

export default {
    name: "Nav",
    components: {
        NavLogo,
        JoinEmailList,
        AccountOptions,
    },
    props: {
        whiteNavToStart: Boolean,
    },
    data() {
        return {
            prevent_transition_on_resize: false,
            resize_timeout: null,
            // lg_to_small: 0,
            previous_width: 0,
            navHasBg: false,
            menuLinks: [
                {
                    name: "Home",
                    to: "home",
                },
            ],
            strings: text,
        };
    },
    computed: {
        // user() {
        //     if (!this.$auth.loggedIn()) return null;
        //     return User.query().first();
        // },
        // showMenuOnMob() {
        //     return !this.$store.state.mobileMenuHidden;
        // },
        loggedIn() {
            return this.$auth.loggedIn();
        },
        showWhiteVersion() {
            return !this.navHasBg && this.whiteNavToStart;
        },
        resizeCount() {
            return this.$store.state.resizeCount;
        },
        currentRouteName() {
            return this.$route.name;
        },
    },
    async mounted() {
        window.addEventListener("scroll", this.handleScroll);
        // console.log("mount topnav");
        // await this.$a2i.public.get_multi(["studies", "condition_studies", "conditions"]);
    },
    unmounted() {
        window.removeEventListener("scroll", this.handleScroll);
        // console.log("unmount topnav");
    },
    watch: {
        resizeCount() {
            // if (this.showMenuOnMob) this.CloseMobileMenu();
            // if ((this.previous_width > 991 && window.innerWidth <= 991) || (this.previous_width < 992 && window.innerWidth >= 992)) {
            //     this.lg_to_small += 1;
            // }
            // this.previous_width = window.innerWidth;
            // If the user is resizing the window, turn off our transitions for a reasonable length of time
            // to prevent them seeing any flashes of mobile menu
            // clearTimeout(this.resize_timeout);
            // this.prevent_transition_on_resize = true;
            // this.resize_timeout = setTimeout(() => {
            //     if (this.prevent_transition_on_resize) this.prevent_transition_on_resize = false;
            // }, 1500);
        },
    },
    methods: {
        CloseMobileMenu() {
            // set store showMenuOnMob to false
            this.$store.commit("TOGGLE_MOBILE_MENU_HIDDEN");
        },
        GotoAndClosemenu(e) {
            let routename = e.currentTarget.getAttribute("href");
            // if (routename === "/") routename = this.$auth.loggedIn() ? "/home" : "/";

            this.$store.commit("SET_MOBILE_MENU_HIDDEN");
            // check link clicked not the same as the route to redirect to
            if (this.$route.path !== routename) this.$router.push({ path: routename });
        },
        handleScroll() {
            this.navHasBg = window.scrollY > 0;
        },
    },
};
</script>

<style scoped>
.btn-bg {
    @apply absolute left-0 top-0 w-full h-full rounded-full transition-opacity duration-300 ease-in-out;
}

.nav-btn-padding {
    padding: 7px 16px 8px 16px;
}
</style>
